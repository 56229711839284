.center-xy {
  /* width: inherit; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.copy-container {
  text-align: center;
}

p {
  color: #fff;
  font-size: 24px;
  letter-spacing: 0.2px;
  margin: 0;
}

.error h5,
.error small {
  color: var(--color-bg);
  cursor: default;
}
