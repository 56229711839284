/*    SECTION               */

section#blog {
  margin-top: 1rem;
  margin-bottom: 8rem;
}

section#blog > h2,
section#blog > h5,
section#blog > h6 {
  text-align: center;
  color: var(--color-light);
}

section#blog > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

/*    END SECTION           */

/*        HEADER            */

header.blogHeader {
  height: 45vh;
  padding-top: 7rem;
  overflow: hidden;
}

.blogHeader__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/*        END HEADER      */

.blog__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.blog__item {
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.blog__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.blog__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.blog__item h3 {
  margin: 1.2rem 0 2rem;
}

.blog__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

/* Media queries */

@media screen and (max-width: 1024px) {
  header.blogHeader {
    height: 34vh;
  }

  .blog__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

@media screen and (max-width: 600px) {
  header.blogHeader {
    height: 50vh;
  }

  .blog__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .blog__item {
    margin: 0 1rem;
  }
}
