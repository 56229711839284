.blogPreview__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.blogPreview__item {
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.blogPreview__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.blogPreview__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.blogPreview__item h3 {
  margin: 1.2rem 0 2rem;
}

.blogPreview__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

#blogPreview h6:hover {
  cursor: pointer;
  color: white;
  transition: var(--transition);
}

/* Media queries */

@media screen and (max-width: 1024px) {
  .blogPreview__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

@media screen and (max-width: 600px) {
  .blogPreview__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .blogPreview__item {
    margin: 0 1rem;
  }
}
